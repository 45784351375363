<template>
  <v-container class="d-flex align-center justify-center main-container fill-height">          
    <v-sheet class="webcam-container" color="transparent">
      <video ref="video" class="video" autoplay playsinline></video>
      <img src="@/assets/frame2.png" class="frame" alt="Frame">
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  name: 'HelloWorld',
  mounted() {
    this.startWebcam();
  },
  methods: {
    startWebcam() {
      navigator.mediaDevices.getUserMedia({ video: { facingMode: 'user' } })
        .then(stream => {
          this.$refs.video.srcObject = stream;
        })
        .catch(err => {
          console.error("Error accessing the webcam: ", err);
        });
    }
  }
}
</script>

<style scoped>
.main-container {
  height: 80vh
}
.webcam-container {
  position: relative;
  width: 370px;
  height: 540px;
}
.frame {
  position: absolute;
  top: 0;
  left: 0;
  height: 540px;
  width: 370px;
  object-fit: cover;
}
.video {
  position: absolute;
  top: 15%; /* Adjust based on the frame */
  left: 15%; /* Adjust based on the frame */
  width: 70%; /* Adjust based on the frame */
  height: 75%; /* Adjust based on the frame */
  object-fit: cover;
  border: 2px solid #ccc;
  border-radius: 8px;
}
</style>