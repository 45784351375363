<template>
  <v-app>
    <v-main>
      <HelloWorld/>
    </v-main>
  </v-app>
</template>

<script>
import HelloWorld from './components/HelloWorld';

export default {
  name: 'App',

  components: {
    HelloWorld,
  },

  data: () => ({
    //
  }),
};
</script>

<style>
  @import '~vuetify/dist/vuetify.min.css';
  
  html, body, #app {
    height: 100%;
    margin: 0;
  }
  
  #app {
    background-image: url('@/assets/muro.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
</style>
